import React from "react";

const Header = () => {
  return (
    <div>
      <header className="header" id="newheader">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/SHRAVANA%20MASAM%20copy.png?alt=media&token=2b030712-a3c8-4c45-87c2-d5f1486ab464"
          alt="SWIO Logo"
        />
      </header>
    </div>
  );
};

export default Header;
