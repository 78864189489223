import React, { useEffect } from "react";
import { motion } from "framer-motion";

const SplashScreen = ({ setShowSplash }) => {
  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 4000);
  }, [setShowSplash]);

  return (
    <motion.div
      initial={{ opacity: 1, y: 0 }}
      animate={{ opacity: 0, y: -900,  }}
      transition={{ 
        opacity: { duration: 1, delay: 3, ease: "easeInOut" },
        y: { type: "spring", stiffness: 100, damping: 100, delay: 3 }
      }}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        overflow: "hidden",
      }}
    >
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 1, delay: 3, ease: "anticipate" }}
        style={{ display: "flex", color: "red", fontSize: "5rem" }}
      >
        <svg>
          <text x="50%" y="50%" dy=".35em" text-anchor="middle">
            swio
          </text>
        </svg>
      </motion.div>
    </motion.div>
  );
};

export default SplashScreen;

