import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const LetterPullup = ({ words }) => {
  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        when: "beforeChildren",
      },
    },
  };

  const letterVariants = {
    hidden: { y: "100%" },
    visible: {
      y: 0,
      transition: {
        type: "spring",
        damping: 8,
        stiffness: 250,
      },
    },
  };
  const [startAnimation, setStartAnimation] = useState(false);
  const letters = words.split("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 1000); // 1 second delay

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <motion.div
      className="letter-pullup-container"
      variants={containerVariants}
      initial="hidden"
      animate={startAnimation ? "visible" : "hidden"}
      transition={{
        repeat: Infinity,
        repeatDelay: 3,
      }}
    >
      {letters.map((letter, index) => (
        <motion.span key={index} variants={letterVariants} className="letter">
          {letter === "" ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default LetterPullup;
